import { useState } from 'react';
import { U21Alert, U21Button } from 'app/shared/u21-ui/components';
import {
  TransactionHistogramGraph,
  TransactionHistogramProps,
} from 'app/modules/transactions/components/TransactionHistogramGraph';

const MAX_GRAPHS = 20 * 500; // 20 transaction codes * 500 total txns}

// wrapper for TransactionHistogramInner that shows an alert if the histogram data is too large
export const TransactionHistogram = ({
  histogram,
  loading,
  formatYAxis = true,
}: TransactionHistogramProps) => {
  const [hideAlert, setHideAlert] = useState(false);

  const numTransactionCodes = histogram.transaction_codes.length;
  const numTransactions =
    histogram.histogram_data.in.length + histogram.histogram_data.out.length;
  const numGraphs = numTransactionCodes * numTransactions;

  if (numGraphs > MAX_GRAPHS && !hideAlert) {
    return (
      <U21Alert severity="warning">
        Transaction history has been hidden for this entity because there are a
        high number of transactions
        {numTransactionCodes > 10
          ? ` and ${numTransactionCodes} transaction types`
          : ''}
        . With this much data, you may experience performance issues.{' '}
        <U21Button
          variant="text"
          color="primary"
          onClick={() => setHideAlert(true)}
        >
          Show me anyway
        </U21Button>
        .
      </U21Alert>
    );
  }

  return (
    <TransactionHistogramGraph
      histogram={histogram}
      loading={loading}
      formatYAxis={formatYAxis}
    />
  );
};
